exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-booking-index-js": () => import("./../../../src/templates/booking/index.js" /* webpackChunkName: "component---src-templates-booking-index-js" */),
  "component---src-templates-centre-centres-js": () => import("./../../../src/templates/centre/centres.js" /* webpackChunkName: "component---src-templates-centre-centres-js" */),
  "component---src-templates-centre-index-js": () => import("./../../../src/templates/centre/index.js" /* webpackChunkName: "component---src-templates-centre-index-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news/article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/news/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

